import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import iphoneMockUp from '../images/iphone-mock-up.png';
import { Icon } from '@iconify/react';

const Home = () => {
  const [connect] = useOutletContext();
  const [valueSearch, setValueSearch] = useState('');
  const [tick, setTick] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    window.location = `https://app.voicemix.io/search?account=${valueSearch}&connect=${connect}`;
  };

  const redirectAccount = () => {
    window.location = 'https://app.voicemix.io?connect=true';
  };

  useEffect(() => {
    let timeout;
    if (tick) {
      timeout = setTimeout(() => {
        setTick(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [tick]);

  return (
    <>
      <div className='bg-hero-gradient pt-[5.0625rem]'>
        <div className='max-w-[1140px] px-8 pt-15 sm:pt-[4.25rem] lg:mx-auto'>
          <div className='flex flex-col sm:flex-row sm:items-center sm:justify-center sm:gap-15 pb-[6.25rem] sm:mr-[3.25rem]'>
            <div className='-mb-[2.25rem] w-[10.4375rem]  sm:w-[15.75rem] sm:mb-0 self-end'>
              {/* <div className='videomockup self-end'> */}
              {/* <video className='' width='140'>
                <source src={videoMockUp} type='video/mp4' />
              </video> */}
              {/* <img src={iphoneMockUp} alt='iphone 13' className='z-50' /> */}
              {/* </div> */}
              <img
                src={iphoneMockUp}
                alt='iphone 13'
                className='animate-[floating_6s_ease-in-out_infinite]'
              />
            </div>
            <div className='flex flex-col gap-4 sm:gap-6'>
              <span className='text-white font-extrabold text-[3.125rem] leading-none sm:text-[4.375rem]'>
                Our
                <div className='text-spreen-green flex'>
                  <div className='overflow-hidden h-[3rem]  sm:h-[3.875rem]'>
                    <span className='block h-full animate-[spin-words_6s_ease-in-out_infinite]'>
                      wallets
                    </span>
                    <span className='block h-full animate-[spin-words_6s_ease-in-out_infinite]'>
                      NFT's
                    </span>
                    <span className='block h-full animate-[spin-words_6s_ease-in-out_infinite]'>
                      tokens
                    </span>
                    <span className='block h-full animate-[spin-words_6s_ease-in-out_infinite]'>
                      addresses
                    </span>
                    <span className='block h-full animate-[spin-words_6s_ease-in-out_infinite]'>
                      wallets
                    </span>
                  </div>
                </div>
                speak
              </span>
              <span className='text-xl text-spreen-green sm:text-[1.75rem] sm:leading-[2.125rem] sm:mb-10'>
                Are them saying what <br /> we want to say?
              </span>
            </div>
          </div>
          <div className='flex flex-col gap-2 sm:gap-4 pb-[6.25rem] max-w-[37.5rem] sm:mx-auto'>
            <form onSubmit={handleSubmit} className='flex w-full'>
              <label className='relative w-full'>
                <input
                  value={valueSearch}
                  onChange={(e) => setValueSearch(e.target.value)}
                  placeholder=' '
                  className='w-full bg-white/20 text-white sm:text-2xl border-t border-l border-b border-white rounded-l appearance-none py-4 pl-4 sm:py-6 sm:pl-6 border-dark-platinum  focus:outline-none focus:ring-1 focus:ring-spreen-green  focus:border-transparent'
                />
                <span className='truncate w-fill text-white sm:text-2xl absolute flex gap-1 items-center mx-4 mt-4  sm:mx-6 sm:mt-6 left-0 top-0  cursor-text transition-transform search bg-transparent'>
                  <Icon
                    icon='mdi:account-search'
                    className='text-base sm:text-2xl'
                  />
                  Search by Address or ENS
                </span>
              </label>
              <button
                type='submit'
                className='p-4 sm:p-6 border border-spreen-green rounded-r bg-spreen-green/20'
              >
                <Icon
                  icon='ant-design:search-outlined'
                  className='text-spreen-green sm:hidden'
                />
                <span className='hidden sm:inline text-spreen-green text-2xl font-semibold'>
                  Search
                </span>
              </button>
            </form>
            <button
              onClick={() => redirectAccount()}
              className='w-full bg-white rounded p-4 sm:p-6 sm:text-2xl font-medium text-charcoal'
            >
              Access with wallet
              <Icon
                icon='cib:ethereum'
                className='ml-2 inline text-xl sm:text-2xl'
              />
            </button>
          </div>
          <div className='flex flex-col gap-6 sm:gap-[3.25rem]  pb-[6.25rem]  max-w-[37.5rem] sm:mx-auto'>
            <span className='text-white text-[1.75rem] leading-[2rem] font-semibold sm:text-[2.75rem] sm:leading-[3.625rem]'>
              Interact with the network and discover{' '}
              <span className='text-spreen-green font-bold'>
                the social side of the web3
              </span>
            </span>
            <div className='flex justify-around sm:justify-between flex-wrap'>
              <div className='flex flex-col gap-2 sm:gap-4 items-center '>
                <div className='bg-white p-5 sm:p-10 rounded-xl sm:rounded-[1.375rem]'>
                  <Icon
                    icon='mdi:account'
                    className='text-[2.125rem] sm:text-[4rem] text-neon-blue'
                  />
                </div>
                <span className='text-white text-sm sm:text-2xl'>Profile</span>
              </div>
              <div className='flex flex-col gap-2 sm:gap-4 items-center '>
                <div className='bg-white p-5 sm:p-10 rounded-xl sm:rounded-[1.375rem]'>
                  <Icon
                    icon='mdi:account-search'
                    className='text-[2.125rem] sm:text-[4rem] text-neon-blue'
                  />
                </div>
                <span className='text-white text-sm sm:text-2xl'>Explore</span>
              </div>
              <div className='flex flex-col gap-2 sm:gap-4 items-center '>
                <div className='bg-white p-5 sm:p-10 rounded-xl sm:rounded-[1.375rem]'>
                  <Icon
                    icon='mdi:heart'
                    className='text-[2.125rem] sm:text-[4rem] text-neon-blue'
                  />
                </div>
                <span className='text-white text-sm sm:text-2xl'>
                  Favorites
                </span>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-6 sm:gap-[3.25rem]  pb-[6.25rem]  max-w-[37.5rem] sm:mx-auto'>
            <a
              href='https://discord.gg/qVZ6peqrg9'
              target='_blank'
              rel='noopener noreferrer'
              className='mt-6 py-5 px-6 flex items-center gap-2 flex-wrap justify-between bg-white/20 rounded border-[0.5px] border-white'
            >
              <span className='uppercase text-white sm:text-lg font-bold tracking-[0.2em]'>
                community
              </span>
              <span className='bg-white py-2 rounded text-xs sm:text-sm leading-none font-medium px-6'>
                Join
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
