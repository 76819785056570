import isologotipo from '../images/brand/isologotipo.svg';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className='top-[100vh] sticky'>
      <div className='p-15 max-w-[1140px] mx-auto'>
        <div className='  lg:items-center flex flex-col lg:flex-row gap-5 lg:justify-between'>
          <div>
            <img
              src={isologotipo}
              alt='isologotipo'
              className='h-[18px] mx-auto lg:mx-0 cursor-pointer'
            />
            <span className='text-[0.9375rem] pt-2.5 hidden lg:block'>
              ©2021 Voicemix, Inc. All rights reserved.
            </span>
          </div>
          <div className='flex flex-col gap-5 lg:gap-8 lg:flex-row'>
            <div className='flex flex-col items-center lg:items-end uppercase'>
              <span className='font-light text-sm'>Join our community!</span>
              <span className='font-bold text-xs cursor-pointer'>
                <a
                  href='https://twitter.com/voicemix'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Twitter
                </a>{' '}
                |{' '}
                <a
                  href='https://discord.gg/qVZ6peqrg9'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Discord
                </a>
              </span>
            </div>
            <div className='flex flex-col items-center lg:items-end uppercase'>
              <span className='font-light text-sm'>Read more!</span>
              <span className='font-bold text-xs cursor-pointer'>
                Litepaper | <Link to='/about'>Abstract</Link>
              </span>
            </div>
          </div>
          <span className='text-[0.9375rem] text-center lg:hidden '>
            ©2021 Voicemix, Inc. All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
