import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { Outlet } from 'react-router-dom';
import Navbar from './components/Navbar.js';
import Footer from './components/Footer.js';

const App = () => {
  const [defaultAccount, setDefaultAccount] = useState('');
  const [ensName, setEnsName] = useState(null);
  const [connect, setConnect] = useState(false);

  let provider;
  if (window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
  }

  const connectwalletHandler = () => {
    if (window.ethereum) {
      provider
        .send('eth_requestAccounts', [])
        .then(async () => {
          await accountChangedHandler(provider.getSigner());
          setConnect(true);
        })
        .catch((error) => console.log(error));
    } else {
      alert('Install metamask');
    }
  };
  const accountChangedHandler = async (newAccount) => {
    const address = await newAccount.getAddress();
    setDefaultAccount(address);
    const ethereumName = await provider.lookupAddress(address);
    setEnsName(ethereumName);
  };

  return (
    <>
      <Navbar
        address={defaultAccount}
        connect={connectwalletHandler}
        ensName={ensName}
      />
      <Outlet context={[connect]} />
      <Footer />
    </>
  );
};

export default App;
