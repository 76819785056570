import { useState, useEffect } from 'react';
import Logo from '../images/brand/logo.svg';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import ClipboardJS from 'clipboard';

const Navbar = ({ address, connect, ensName }) => {
  const [copied, setCopied] = useState(false);
  let clipboard = new ClipboardJS('.group');

  useEffect(() => {
    let timeout;
    clipboard.on('success', function (e) {
      setCopied(true);
      e.clearSelection();
      timeout = setTimeout(() => {
        setCopied(false);
      }, 500);
    });

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <>
      <div className='w-full text-white z-20 absolute top-0'>
        <div className='relative gap-2 max-w-[1140px] mx-auto px-8 py-6 md:px-15 w-full flex flex-wrap items-center justify-between'>
          <Link to='/'>
            <img src={Logo} alt='logo' className='cursor-pointer' />
          </Link>
          <div className='flex gap-2 items-center md:hidden text-xs leading-1'>
            <Link to='/about'>About Us</Link>

            <button
              data-clipboard-text={address}
              onClick={address === '' ? connect : undefined}
              className='group py-2.5 px-[0.875rem] bg-[#05F182]/25 border-[0.6px] border-[#05F182]  rounded-[2px] flex gap-1 font-medium text-spreen-green justify-center'
            >
              {address !== '' && address !== null
                ? ensName !== null
                  ? ensName
                  : address.slice(0, 5) + '...' + address.slice(-4)
                : 'Access'}

              <Icon
                icon='cib:ethereum'
                color='#05f182'
                className={`${address !== '' && 'group-hover:hidden'}`}
              />
              <Icon
                icon='akar-icons:copy'
                color='#05f182'
                className={`${
                  address !== '' && 'group-hover:inline-flex'
                } hidden`}
              />
              {copied && (
                <Icon icon='teenyicons:tick-circle-outline' color='#05f182' />
              )}
            </button>
          </div>
          <div className=' items-center gap-2 md:gap-3 text-xs leading-1 relative hidden md:flex'>
            <Link to='/about'>About Us</Link>
            <button
              onClick={address === '' ? connect : undefined}
              data-clipboard-text={address}
              className='group py-2.5 px-[0.875rem] bg-[#05F182]/25  hover:bg-[rgba(5,241,130,0.35)] border-[0.6px] border-[#05F182] rounded-[2px] flex gap-1 text-xs leading-3 font-medium text-spreen-green justify-center'
            >
              {address !== '' && address !== null
                ? ensName !== null
                  ? ensName
                  : address.slice(0, 5) + '...' + address.slice(-4)
                : 'Access'}

              <Icon
                icon='cib:ethereum'
                color='#05f182'
                className={`${address !== '' && 'group-hover:hidden'}`}
              />
              <Icon
                icon='akar-icons:copy'
                color='#05f182'
                className={`${
                  address !== '' && 'group-hover:inline-flex'
                } hidden`}
              />
              {copied && (
                <Icon icon='teenyicons:tick-circle-outline' color='#05f182' />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
