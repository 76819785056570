const About = () => {
  return (
    <>
      <div className='bg-hero-gradient text-white pt-[4.875rem] md:pt-[5.375rem]'>
        <div className='max-w-[1140px] mx-auto'>
          <div className='px-8 py-[4.375rem] md:py-[5.625rem] flex flex-col gap-8 max-w-[516px] mx-auto'>
            <h1 className='font-bold text-spreen-green text-2xl tracking-[0.2em] uppercase'>
              Abstract
            </h1>
            <div>
              <h2 className='mb-1 font-medium text-xl'>Our vision</h2>
              <span className='text-xs'>
                Own your voice, share a world, build your future.
              </span>
            </div>
            <div>
              <h2 className='mb-1 font-medium text-xl'>Our story</h2>
              <p className='text-xs pb-4'>
                Voicemix was forged from the understanding of horrible monsters,
                without 3 heads or 6 eyes, who need silence to exist. A
                particular food that makes its spectrum grow in proportion to
                the darkness in which it immerses us. Breaking the silence is
                that we stop being a medium for the reality of others. Although
                we are constituted there, it is essential to constantly and
                reflectively distance ourselves and even with greater tenacity
                when they are categorically imposed on us in a voracious way.
              </p>{' '}
              <p className='text-xs pb-4'>
                Because it is through a voice that one exists and it is by
                speaking that one creates reality, it is vitally important to
                identify which is the voice of each one, how it is composed in
                the spectrum of topics and why it is important to have one.
              </p>
              <p className='text-xs pb-4'>
                In turn, it is through the communication process that humans
                have been able to create consensus, cooperate and have common
                projects to build future and mobilize the present.
              </p>
              <p className='text-xs pb-4'>
                What is certain is that we all need to say, express ourselves
                and find meaning. The multiplicity that determines us and pushes
                us to continue searching is a sign of complexity along the way.
                Both those who know where to find something important to
                themselves and those who are in the process of discovering it
                can find in Voicemix the way to give value to what best
                represents it.
              </p>
              <p className='text-xs'>
                Thus, as a mirror of ourselves, it is possible to understand
                your purpose and reaffirm your values ​​by constituting the
                society 'between' your voice and that of others.
              </p>
            </div>
            <div>
              <h2 className='mb-1 font-medium text-xl'>Why choose us</h2>
              <p className='text-xs pb-4'>
                Because although we know that the platform comes to propose a
                way of apprehending life, we are respectful of individual
                freedom of action and the implicit truth that the exercise of
                this freedom entails in each one of your choices.
              </p>

              <p className='text-xs'>
                Impartiality is very important to us. Although we have a defined
                active voice, it is in our spirit to provide you with a
                transparent digital space using as many tools and techniques as
                possible to guarantee it.
              </p>
            </div>
            <div>
              <h2 className='mb-1 font-medium text-xl'>
                The people behind Voicemix
              </h2>
              <p className='text-xs'>
                We are young professionals from different parts of the world,
                united and committed to freedom, to develop a meaningful project
                for our lives and that of those around us. We embrace diversity
                by forming a global team since each social and historical
                context enriches our mission and allows us to create a medium in
                which each one feels artifice of their reality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
